import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Lanches = () => (
  <>
    <SEO title="Lanches" />

    <Menu className="menu">
      <Slide left cascade duration={500}>
        <h1 className="menu-title">Lanches</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cachorro Quente (Simples)</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">15,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho, 2 Salsichas, Mostarda, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cachorro Quente (Completo)</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho, 2 Salsichas, Milho, Bacon, Batata Palha, Mostarda, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Misto Quente</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>
          </div>
        </div>

        <p className="item-desc">
          3 Fatias de Queijo, 3 Fatias de Presunto, Alface, Tomate, Maionese e
          Catchup
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Americano</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>
          </div>
        </div>

        <p className="item-desc">
          2 Fatias de Queijo, 2 Fatias de Presunto, Bacon, Ovo, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">X-Salada</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">24,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Hambúrguer, Queijo, Alface, Tomate, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">X-Bacon</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">26,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Hambúrguer, Bacon, Queijo, Alface, Tomate, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">X-Egg</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">26,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Hambúrguer, Queijo, Ovo, Alface, Tomate, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Bauru da Vó Luzia</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">30,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Pão de Baguete, Filé, Queijo, Tomate, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Bauru à Moda</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">36,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Pão de Baguete, Filé, Queijo, Presunto, Bacon, Tomate, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Hambúrguer</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">24,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Hambúrguer, Queijo, Alface, Tomate, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Hambúrguer à Moda</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">30,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Hambúrguer, Ovo, Bacon, Queijo, Presunto, Alface, Tomate, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Hambúrgão</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">36,00</h4>
          </div>
        </div>

        <p className="item-desc">
          2 Hambúrguer, Ovo, Bacon, Queijo, Presunto, Alface, Tomate, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cheddar Burguer</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">26,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Hambúrguer, Cheddar, Cebola caramelizada, Alface, Tomate, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">X-Filé</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">30,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Filé, Queijo Tomate, Alface, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Filé à Moda</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">36,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Filé, Queijo, Presunto, Ovo, Bacon, Alface, Tomate, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Filézão</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Filé, Queijo, Presunto, Ovo, Bacon, Milho, Alface, Tomate, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Acebolado</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Filé, Cebola, Queijo, Presunto, Ovo, Bacon, Alface, Tomate, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">X-Gordão</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">40,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Hambúrguer, Filé, Queijo, Presunto, Ovo, Bacon, Milho, Ervilha,
          Alface, Tomate, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">X-Frango</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">30,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Filet de Frango, Queijo, Alface, Milho, Catupiry, Tomate, Maionese e
          Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Frango à Moda</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">36,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Filet de Frango, Queijo, Presunto, Ovo, Bacon, Milho, Catupiry,
          Alface, Tomate, Maionese e Catchup.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Lanche Jr.</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">26,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Hambúrguer, Queijo, Maionese e Catchup - Acompanha: Fritas.
        </p>
      </Item>

      <Slide left cascade duration={500}>
        <h1 className="menu-title">Acompanhamentos</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">4,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Cheddar</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">4,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Carne de Hambúrguer</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">4,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Ovo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">4,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Bacon</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">4,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Palha</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">4,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Purê de Batata</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">4,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Maionese de Alho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">2,50</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">
            Obs: Lanches servidos no prato terão acréscimo de
          </h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">5,00</h4>
          </div>
        </div>
      </Item>
    </Menu>
  </>
);

export default Lanches;
